import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import {
  MatFormField,
  MatInput,
  MatInputModule,
} from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatOption, MatSelect } from '@angular/material/select';

import { AutocompletePipe, currencyAutocomplete } from '@pu/sdk';
import {
  CurrencyService,
  dichotomyAnswerOptions,
  HighlightMatchComponent,
  PaymentsService,
} from '@bo/common';

import { RadioToggleBarComponent } from '../../../../radio-toggle/radio-toggle-bar/radio-toggle-bar.component';
import { RadioToggleItemComponent } from '../../../../radio-toggle/radio-toggle-item/radio-toggle-item.component';
import type { DepositsFilterFinanceForm } from '../finance-filter.form';

@Component({
  selector: 'bo-deposit-finance-filter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInput,
    MatFormField,
    MatSelect,
    MatOption,
    MatRadioButton,
    MatRadioGroup,
    MatLabel,
    MatInputModule,
    MatFormFieldModule,
    MatCheckbox,
    MatDateRangeInput,
    MatDatepickerToggle,
    MatDateRangePicker,
    MatStartDate,
    MatEndDate,
    RadioToggleBarComponent,
    RadioToggleItemComponent,
    MatAutocompleteTrigger,
    MatAutocomplete,
    HighlightMatchComponent,
    AutocompletePipe,
    RadioToggleBarComponent,
  ],
  templateUrl: './finance-filter.component.html',
  styleUrl: '../../../../filters/stylesheets/_form.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositFinanceFilterComponent {
  protected readonly currencyAutocomplete = currencyAutocomplete;

  public form = input.required<DepositsFilterFinanceForm>();
  private readonly currencyService = inject(CurrencyService);

  protected readonly currencies$ = this.currencyService.currencies$;
  private readonly paymentsService = inject(PaymentsService);
  public readonly paymentMethods$ = this.paymentsService.paymentMethods$;
  protected readonly yesNoOptions = dichotomyAnswerOptions;
}
