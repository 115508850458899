import type { EnvironmentProviders } from '@angular/core';
import {
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import type {
  CanActivateChildFn,
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { LOCATION } from '@ng-web-apis/common';

import { provideFactory, provideMultiValue } from '@pu/sdk';

import type { AccessGroupLicense } from './access-group';
import type { LicenseFeature, LicenseOptions } from './license';
import { License } from './license';
import { SUPPORTED_LICENSES } from './supported-licenses';

const UNFILTERED_LICENSES = new InjectionToken<readonly License[]>(
  'UNFILTERED_LICENSES',
);

export function provideLicense(
  group: string,
  title: string,
  origin: string,
  hosts: Iterable<string> = [],
  features: Iterable<LicenseFeature>,
  options?: LicenseOptions,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideMultiValue(
      UNFILTERED_LICENSES,
      License.create(
        group as AccessGroupLicense,
        title,
        origin,
        hosts,
        features,
        options,
      ),
    ),
  ]);
}

export function provideFirstAvailableLicense(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideFactory(License, () => {
      const [license] = inject(SUPPORTED_LICENSES);

      if (license === undefined) {
        throw new Error('License not available');
      }

      return license;
    }),
  ]);
}

function provideKzLicense(origin: string): EnvironmentProviders {
  return provideLicense(
    'kz',
    'Kazakhstan',
    origin,
    ['bo-kz.tech-pu.com', 'bo-kz-preprod.tech-pu.com'],
    [
      'betting-status',
      'digitain-freebet',
      'sport',
      'sport-bet',
      'sport-win',
      'sport-digitain',
      'freebet',
    ],
  );
}

function provideUaLicense(origin: string): EnvironmentProviders {
  return provideLicense(
    'ua',
    'Ukraine',
    origin,
    ['bo.tech-pu.com', 'bo-preprod.tech-pu.com'],
    [
      'betby',
      'casino-status',
      'casino',
      'casino-bet',
      'casino-win',
      'cashback',
      'dw-tax',
      'freespin',
      'lottery',
      'pincoins',
      're-verification',
      'tax-calculation',
      'tax-information',
      'tv-games',
      'vs',
    ],
  );
}

function provideComLicense(origin: string): EnvironmentProviders {
  return provideLicense(
    'com',
    'COM',
    origin,
    [
      'bo.tech-pu.com',
      'bo-preprod.tech-pu.com',
      'bo-product.pincowin.tech',
      'bo-product.prepincowin.tech',
    ],
    [
      'betby',
      'betting-status',
      'casino-status',
      'cashback',
      'casino',
      'casino-bet',
      'casino-win',
      'currency',
      'cpf',
      'geo',
      'freebet',
      'freespin',
      'lottery',
      'pincoins',
      'sport',
      'sport-bet',
      'sport-win',
      'sport-betby',
      'sport-digitain',
      'bespoke-sport',
      'digitain-freebet',
      'bespoke-freebet',
      'tv-games',
      'vs',
    ],
  );
}

export function provideDevLicenses(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideUaLicense('https://gw-bo.ua.dev.product.pin-up.dev'),
    provideComLicense('https://gw-bo.com.dev.product.pin-up.dev'),
    provideKzLicense('https://gw-bo.kz.dev.product.pin-up.dev'),
    provideFactory(SUPPORTED_LICENSES, () => {
      const licenses = inject(UNFILTERED_LICENSES);
      const host = localStorage.getItem('debugLocationHost');

      return host === null
        ? licenses
        : licenses.filter(({ hosts }) => hosts.has(host));
    }),
  ]);
}

export function provideProdLicenses(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideUaLicense('https://gw-bo.pin-up.ua'),
    provideComLicense('https://gw-bo.pin-up.world'),
    provideKzLicense('https://gw-bo.pin-up.kz'),
    provideFactory(SUPPORTED_LICENSES, () => {
      const { host } = inject(LOCATION);

      return inject(UNFILTERED_LICENSES).filter(({ hosts }) => hosts.has(host));
    }),
  ]);
}

export function canLicenseSupport(
  feature: LicenseFeature,
): CanActivateChildFn & CanActivateFn & CanMatchFn {
  return () => inject(License).supports(feature);
}
