<span>
  @if (showSign() && amount() > 0) {
    <span>+</span>
  }
  @if (negative()) {
    <span>-</span>
  }
  <span>{{ parts().decimal }}.</span>
  <span class="fraction">{{ parts().fraction }}</span>
</span>

@if (currency()) {
  <span>{{ currency() }}</span>
}
