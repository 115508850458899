import type { FeatureFlag } from '../../feature-flags.model';
import type { ToggleMenuPosition } from './feature-flags-toggle-menu.model';

export const toggleMenuPositionToCssClassMap: Record<
  ToggleMenuPosition,
  [string, string]
> = {
  bottomLeft: ['bottom', 'left'],
  bottomRight: ['bottom', 'right'],
  topLeft: ['top', 'left'],
  topRight: ['top', 'right'],
};

export const featureFlagToLabelMap: Record<FeatureFlag, string> = {
  use_mocks: 'Use mocks',
  gtm_debug: 'GTM debug',
};
