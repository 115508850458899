import { inject, Injectable } from '@angular/core';
import { BillingRemoteService } from '@pinup-grpc/pinup/stat/report/billing/billing_ng';
import type {
  ListTransactionsRequest,
  ListTransactionsRequest_Filter,
  ListTransactionsResponse,
} from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import type { Observable } from 'rxjs';

import type { Pagination } from '@bo/common';
import { DEFAULT_CONVERT_CURRENCY } from '@bo/common';

@Injectable()
export class TransactionsProvider {
  private readonly billingRemoteService = inject(BillingRemoteService);

  public getTransactions(
    { currentPage: page, pageSize }: Pagination,
    filter: Partial<ListTransactionsRequest_Filter>,
    period?: ListTransactionsRequest['period'],
  ): Observable<ListTransactionsResponse> {
    return this.billingRemoteService.listTransactions({
      convertCurrency: DEFAULT_CONVERT_CURRENCY as string,
      pagination: { page, pageSize },
      period,
      sorting: [],
      filter,
    });
  }
}
