import { inject, Injectable } from '@angular/core';
import type { Sort } from '@angular/material/sort';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  combineLatest,
  map,
  merge,
  shareReplay,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import type { PlayerSession } from '@pu/grpc';
import { SessionType } from '@pu/grpc';
import {
  PAGINATION_MAX,
  PAGINATION_PANEL_MANAGER,
  PlayerService,
} from '@bo/common';

import { DEFAULT_ACTIVE_SESSIONS_DATA_SHOW } from './profile-sessions.const';
import { ProfileSessionsProviderService } from './profile-sessions.provide.service';
import { sortSessions } from './profile-sessions.utils';

@Injectable()
export class ProfileSessionsService {
  public sessionsProviderService = inject(ProfileSessionsProviderService);
  public readonly playerId$ = inject(PlayerService).playerId$;

  private readonly pagination$ = inject(PAGINATION_PANEL_MANAGER).source$;
  public readonly isAllActiveSessions$ = new BehaviorSubject(false);
  public readonly activeSessionsSortData$ = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });
  public readonly inactiveSessionsSortData$ = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });

  public rawActiveSessions$ = this.playerId$.pipe(
    switchMap((playerId) =>
      this.sessionsProviderService.findPlayerSession(
        playerId,
        SessionType.ACTIVE,
        PAGINATION_MAX,
      ),
    ),
    shareReplay(1),
  );

  public rawInactiveSessions$ = combineLatest([
    this.pagination$,
    this.playerId$,
  ]).pipe(
    switchMap(([pagination, playerId]) =>
      this.sessionsProviderService.findPlayerSession(
        playerId,
        SessionType.INACTIVE,
        pagination,
      ),
    ),
    shareReplay(1),
  );

  public activeSessions$ = this.rawActiveSessions$.pipe(
    map(({ sessions }) => sessions),
  );

  public inactiveSessions$ = this.rawInactiveSessions$.pipe(
    map(({ sessions }) => sessions),
  );

  public activeSessionsTotal$ = this.rawActiveSessions$.pipe(
    map(({ pagination: { totalPages, totalItems } }) => ({
      totalPages,
      totalItems,
    })),
  );

  public inactiveSessionsTotal$ = this.rawInactiveSessions$.pipe(
    map(({ pagination: { totalPages, totalItems } }) => ({
      totalPages,
      totalItems,
    })),
  );

  public slicedActiveSessions$: Observable<PlayerSession[]> = merge(
    this.activeSessions$,
    this.isAllActiveSessions$,
  ).pipe(
    withLatestFrom(this.activeSessions$, this.isAllActiveSessions$),
    map(([, sessions, isAllActiveSessions]) =>
      isAllActiveSessions
        ? sessions
        : sessions.slice(
            DEFAULT_ACTIVE_SESSIONS_DATA_SHOW.from,
            DEFAULT_ACTIVE_SESSIONS_DATA_SHOW.to,
          ),
    ),
  );

  public sortedActiveSessions$: Observable<PlayerSession[]> = merge(
    this.slicedActiveSessions$,
    this.activeSessionsSortData$,
  ).pipe(
    withLatestFrom(this.slicedActiveSessions$, this.activeSessionsSortData$),
    map(([, slicedActiveSessions, sort]) => {
      return sortSessions(slicedActiveSessions, sort);
    }),
  );

  public sortedInactiveSessions$: Observable<PlayerSession[]> = merge(
    this.inactiveSessions$,
    this.inactiveSessionsSortData$,
  ).pipe(
    withLatestFrom(this.inactiveSessions$, this.inactiveSessionsSortData$),
    map(([, inactiveSessions, sort]) => {
      return sortSessions(inactiveSessions, sort);
    }),
  );

  public setSort(type: SessionType, sort: Sort): void {
    if (type === SessionType.ACTIVE) {
      this.activeSessionsSortData$.next(sort);
    } else {
      this.inactiveSessionsSortData$.next(sort);
    }
  }

  public toggleActiveSession(): void {
    this.isAllActiveSessions$.next(!this.isAllActiveSessions$.getValue());
  }
}
