// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/base/enum.proto (package pinup.stat.report.base, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Deprecated
 *
 * @generated from enum pinup.stat.report.base.Project
 */
export const Project = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.base.Project",
  [
    {no: 0, name: "PROJECT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PROJECT_CASINO", localName: "CASINO"},
    {no: 2, name: "PROJECT_SPORT", localName: "SPORT"},
  ],
);

