import type { Currency } from '@pu/grpc';

import type { MatchResult } from './sorting/autocomplete.pipe';

export function currencyAutocomplete(
  { code }: Currency,
  search: Currency | string,
): MatchResult {
  const normalizedSearch =
    typeof search === 'string' ? search.toLowerCase() : '';
  return {
    text: code,
    index: code.toLowerCase().indexOf(normalizedSearch),
  };
}
