import type { Signal } from '@angular/core';
import { computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { isControlValueChanged } from '@pu/sdk';

import { BoFormGroup } from '../forms/forms.model';

// TODO: analyze how to unlock using unknown instead of any
export class FiltersForm<T extends Record<string, any>> extends BoFormGroup<T> {
  private readonly initialValue = this.value;
  public readonly defaultValue = signal<Parameters<typeof this.patchValue>[0]>(
    {},
  );
  private readonly controlsValue = toSignal(this.valueChanges, {
    initialValue: this.initialValue,
  });

  public count: Signal<number> = computed(() => {
    const defaultValue = this.defaultValue();
    return Object.entries(this.controlsValue())
      .map(([controlName, value]) => {
        const control = this.controls[controlName];

        if (control instanceof FiltersForm) {
          return control.count();
        }

        const originalValue: unknown =
          controlName in defaultValue
            ? defaultValue[controlName]
            : this.initialValue[controlName];

        return isControlValueChanged(originalValue, value) ? 1 : 0;
      })
      .reduce((total, count) => total + count, 0);
  });

  public hasCounterValue = computed(() => this.count() > 0);

  // We use `Parameters<typeof this.patchValue>[0]` to get the type of the partial form value
  public setDefaultValue(value: Parameters<typeof this.patchValue>[0]): void {
    this.defaultValue.set(value);
    for (const [key, control] of Object.entries(this.controls)) {
      if (control instanceof FiltersForm && value[key]) {
        control.setDefaultValue(
          value[key] as Parameters<typeof control.patchValue>[0],
        );
      }
    }
  }
}

export interface RootFiltersForm<T extends {}> {
  getRootValue(): T;
}
