import type { AfterViewInit, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  viewChildren,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';

import type { HorizontalAlignment, VerticalAlignment } from '@pu/sdk';
import { ScrollToAnchorDirective, SvgComponent } from '@pu/sdk';

import { PillToggleBarComponent, PillToggleItemComponent } from '../components';
import type { AnchorLinkWithIcon } from './anchor-links.model';

@Component({
  selector: 'bo-anchor-links',
  standalone: true,
  imports: [
    PillToggleBarComponent,
    PillToggleItemComponent,
    SvgComponent,
    ScrollToAnchorDirective,
    RouterLinkActive,
    RouterLink,
  ],
  templateUrl: './anchor-links.component.html',
  styleUrl: './anchor-links.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorLinksComponent implements OnInit, AfterViewInit {
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly scrollers = viewChildren(ScrollToAnchorDirective);

  public readonly items = input.required<AnchorLinkWithIcon[]>();
  public readonly horizontalAlign = input<HorizontalAlignment>();
  public readonly verticalAlign = input<VerticalAlignment>();

  public ngOnInit(): void {
    const fragment = this.route.snapshot.fragment;
    const activeItem = this.items().find((item) => item.fragment === fragment);

    if (!activeItem) {
      const firstAvailableFragment = this.items()[0].fragment;

      void this.router.navigate([], { fragment: firstAvailableFragment });
    }
  }

  public ngAfterViewInit(): void {
    this.scrollToActiveLink();
  }

  public scrollToActiveLink(): void {
    const activeAnchorId = this.route.snapshot.fragment;

    if (activeAnchorId) {
      const activeScroller = this.scrollers().find(
        (scroller) => scroller.anchorId() === activeAnchorId,
      );

      activeScroller?.scrollToAnchor();
    }
  }
}
