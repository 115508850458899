<div class="form-group p-3" [formGroup]="form()">
  <header class="form-header pb-3">
    <label class="form-label">Other</label>
    <bo-filled-filters-count
      class="clear-cmp"
      [form]="form()"
      data-testid="otherFilterCount"
    />
  </header>

  <mat-form-field>
    <mat-label>GEO</mat-label>
    <mat-select
      aria-label="Select geo location"
      data-testid="geoSelect"
      formControlName="playerGeo"
      multiple
    >
      @for (item of countries$ | async; track item.alpha2) {
        <mat-option [value]="item.alpha2">{{ item.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Domain</mat-label>
    <input
      aria-label="Domain"
      data-testid="domainInput"
      formControlName="domain"
      matInput
      placeholder="Type here"
      type="text"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Click ID</mat-label>
    <input
      aria-label="Click id"
      data-testid="clickIdInput"
      formControlName="clickId"
      matInput
      placeholder="Type here"
      type="text"
    />
  </mat-form-field>
</div>
