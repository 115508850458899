// TODO ask BE to create a separate `message` in which the level and message fields will be clearly defined
/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument */

import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { IMessageTypeRegistry } from '@bufbuild/protobuf';
import type { Interceptor } from '@connectrpc/connect';
import { ConnectError } from '@connectrpc/connect';

import { ErrorInfo } from '@pu/grpc';

const DEPRECATED_TYPE_NAME = 'pinup.std.error_details.v1.ErrorInfo';

// TODO remove after the backend switches to a new version
const ERROR_REGISTRY: IMessageTypeRegistry = {
  findMessage: (typeName) =>
    typeName === ErrorInfo.typeName || typeName === DEPRECATED_TYPE_NAME
      ? ErrorInfo
      : undefined,
};

// TODO different styles for error & warning
export const SNACK_BAR_INTERCEPTOR: Interceptor = (next) => {
  const snackBar = inject(MatSnackBar);

  return async (req) => {
    try {
      return await next(req);
    } catch (reason) {
      if (reason instanceof ConnectError) {
        for (const info of reason.findDetails(ERROR_REGISTRY)) {
          switch (info.metadata.level) {
            case 'warning':
              snackBar.open(info.metadata.message ?? reason.message, 'OK', {
                duration: 3000,
              });
              break;
            case 'error':
              snackBar.open(info.metadata.message ?? reason.message, 'OK', {
                duration: 3000,
              });
              break;
          }
        }
      }

      throw reason;
    }
  };
};
