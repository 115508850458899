// @generated by protoc-gen-es v1.10.0
// @generated from file settings_manager/v1/models/settings.proto (package settings_manager.v1.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp, Value } from "@bufbuild/protobuf";

/**
 * @generated from message settings_manager.v1.models.Setting
 */
export const Setting = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.models.Setting",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fields", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Value} },
    { no: 3, name: "deletedAt", kind: "message", T: Timestamp, opt: true },
  ],
);

