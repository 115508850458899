<div class="pagination-panel">
  <bo-page-size-select
    (pageSizeChange)="handlePageSizeChange($event, true)"
    [pageSize]="pagination.pageSize!"
  />

  <bo-pagination
    (pageChange)="handleCurrentPageChange($event, true)"
    [pagination]="pagination"
  />

  @if (pagination.totalPages) {
    <bo-go-to-pagination
      (pageChange)="handleCurrentPageChange($event, true)"
      [currentPage]="pagination.currentPage"
      [totalPages]="pagination.totalPages"
    />
  }
</div>
