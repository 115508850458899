import { isInteger } from 'lodash-es';

import { stringToBigInt } from '@pu/sdk';

const AMOUNT_OF_KOPECKS = 100n;

export function fromKopecks(amount = 0n): bigint {
  return amount / AMOUNT_OF_KOPECKS;
}

export function toKopecks(amount = 0n): bigint {
  return amount * AMOUNT_OF_KOPECKS;
}

export function fromKopecksOrNull(
  amount: bigint | null | undefined,
): bigint | null {
  return amount ? amount / AMOUNT_OF_KOPECKS : null;
}

export function toKopecksOrUndefined(
  amount: bigint | null | undefined,
): bigint | undefined {
  return amount ? amount * AMOUNT_OF_KOPECKS : undefined;
}

export function floatToBigIntKopecks(value: string): bigint {
  const factor = Number(AMOUNT_OF_KOPECKS);
  const floatNumber = Number(value);
  const integerPart = Math.trunc(floatNumber);
  const fractionalPart = Math.round((floatNumber - integerPart) * 100) / 100;
  const bigIntInteger = BigInt(integerPart);
  const scaledFractionalPart = BigInt(fractionalPart * factor);

  return bigIntInteger * AMOUNT_OF_KOPECKS + scaledFractionalPart;
}

export function stringToKopecks(value: string | null | undefined): bigint {
  if (!value) {
    return 0n;
  }

  return isInteger(Number(value))
    ? toKopecks(stringToBigInt(value))
    : floatToBigIntKopecks(value);
}
