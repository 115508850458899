import { Project } from '@pinup-grpc/pinup/stat/report/base/enum_pb';

import { TransactionOperationType } from '@pu/grpc';
import type { License } from '@bo/common';
import { ProjectType } from '@bo/common';

import type { SimpleSelectOption } from './simple-select-option';

export const PROJECT_TYPE = {
  [ProjectType.Casino]: Project.CASINO,
  [ProjectType.Sport]: Project.SPORT,
};

export const TRANSACTION_OPERATION_TYPES = {
  Unspecified: TransactionOperationType.UNSPECIFIED,
  Deposit: TransactionOperationType.DEPOSIT,
  Withdrawal: TransactionOperationType.WITHDRAWAL,
  Unwithdrawal: TransactionOperationType.UN_WITHDRAWAL,
  Bet: TransactionOperationType.WRITE_BET,
  'Free bet': TransactionOperationType.FREE_BET_WIN,
  'Lottery win': TransactionOperationType.LOTTERY_WIN,
  'Marketing win': TransactionOperationType.MARKETING_WIN,
  'Bonus win': TransactionOperationType.BONUS_WIN,
  Cashback: TransactionOperationType.CASHBACK,
  Revert: TransactionOperationType.REVERT,
};

export function* getProjectTypeOptions(
  license: License,
): Iterable<SimpleSelectOption<Project, string>> {
  if (license.supports('sport')) {
    yield { id: Project.SPORT, value: 'Sport' };
  }
  if (license.supports('casino')) {
    yield { id: Project.CASINO, value: 'Casino' };
  }
}

export function* getOperationTypeOptions(
  license: License,
): Iterable<SimpleSelectOption<TransactionOperationType, string>> {
  yield { id: TransactionOperationType.UNSPECIFIED, value: 'Unspecified' };
  yield { id: TransactionOperationType.DEPOSIT, value: 'Deposit' };
  yield { id: TransactionOperationType.WITHDRAWAL, value: 'Withdrawal' };
  yield { id: TransactionOperationType.UN_WITHDRAWAL, value: 'Unwithdrawal' };
  yield { id: TransactionOperationType.WRITE_BET, value: 'Bet' };

  if (license.supports('freebet')) {
    yield { id: TransactionOperationType.FREE_BET_WIN, value: 'Freebet' };
  }

  if (license.supports('lottery')) {
    yield { id: TransactionOperationType.LOTTERY_WIN, value: 'Lottery win' };
  }
  yield { id: TransactionOperationType.MARKETING_WIN, value: 'Marketing win' };
  yield { id: TransactionOperationType.BONUS_WIN, value: 'Bonus win' };
  yield { id: TransactionOperationType.CASHBACK, value: 'Cashback' };
  yield { id: TransactionOperationType.REVERT, value: 'Revert' };
}
