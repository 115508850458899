import type { Provider, ProviderToken } from '@angular/core';

import { provideClass, provideExisting } from '@pu/sdk';

import type { PaginationPayload } from '../../pagination.model';
import type { PaginationManager } from '../pagination-management.model';
import { DefaultPaginationManager } from './default-pagination-manager';
import { PAGINATION_MANAGER_INITIAL_VALUE } from './default-pagination-value.provider';

export const provideDefaultPaginationManager = <T extends PaginationPayload>(
  provide: ProviderToken<PaginationManager<T>>,
  provideDefaultValue: ProviderToken<T>,
): Provider[] => [
  provideExisting<T>(PAGINATION_MANAGER_INITIAL_VALUE, provideDefaultValue),
  provideClass<PaginationManager<T>>(provide, DefaultPaginationManager<T>),
];
