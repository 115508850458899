import { SumRange } from '@pinup-grpc/pinup/stat/report/base/filtering_pb';

import { stringToBigInt } from '@pu/sdk';
import { DEFAULT_CONVERT_CURRENCY, toKopecks } from '@bo/common';

// can`t use existing toSumRange because diff objects (stat.SumRange vs pinup.stat.report.base.SumRange)
export function toSumRange(
  from: string | null | undefined,
  to: string | null | undefined,
  currency: string = DEFAULT_CONVERT_CURRENCY,
): SumRange {
  return new SumRange({
    from: toKopecks(stringToBigInt(from)),
    to: toKopecks(stringToBigInt(to)),
    currency,
  });
}
