// @generated by protoc-gen-es v1.10.0
// @generated from file settings_manager/v1/admin_settings/admin.proto (package settings_manager.v1.admin_settings, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Group, Schema } from "../models/groups_pb.js";
import { PaginationRequest, PaginationResponse } from "../models/pagination_pb.js";
import { Setting } from "../models/settings_pb.js";

/**
 * @generated from message settings_manager.v1.admin_settings.CreateGroupRequest
 */
export const CreateGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.CreateGroupRequest",
  () => [
    { no: 1, name: "group", kind: "message", T: Group },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.CreateGroupResponse
 */
export const CreateGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.CreateGroupResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.EditGroupRequest
 */
export const EditGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.EditGroupRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.EditGroupResponse
 */
export const EditGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.EditGroupResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.GetGroupRequest
 */
export const GetGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.GetGroupRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.GetGroupResponse
 */
export const GetGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.GetGroupResponse",
  () => [
    { no: 1, name: "group", kind: "message", T: Group },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.ListGroupsRequest
 */
export const ListGroupsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.ListGroupsRequest",
  () => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.ListGroupsResponse
 */
export const ListGroupsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.ListGroupsResponse",
  () => [
    { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.EditGroupFieldRequest
 */
export const EditGroupFieldRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.EditGroupFieldRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "schema", kind: "message", T: Schema },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.EditGroupFieldResponse
 */
export const EditGroupFieldResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.EditGroupFieldResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.AddGroupFieldRequest
 */
export const AddGroupFieldRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.AddGroupFieldRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "schema", kind: "message", T: Schema },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.AddGroupFieldResponse
 */
export const AddGroupFieldResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.AddGroupFieldResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.DeleteGroupFieldRequest
 */
export const DeleteGroupFieldRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.DeleteGroupFieldRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.DeleteGroupFieldResponse
 */
export const DeleteGroupFieldResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.DeleteGroupFieldResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.DeleteGroupRequest
 */
export const DeleteGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.DeleteGroupRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.DeleteGroupResponse
 */
export const DeleteGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.DeleteGroupResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.CreateSettingRequest
 */
export const CreateSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.CreateSettingRequest",
  () => [
    { no: 1, name: "groupName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "setting", kind: "message", T: Setting },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.CreateSettingResponse
 */
export const CreateSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.CreateSettingResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.GetSettingRequest
 */
export const GetSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.GetSettingRequest",
  () => [
    { no: 1, name: "groupName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settingID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.GetSettingResponse
 */
export const GetSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.GetSettingResponse",
  () => [
    { no: 1, name: "setting", kind: "message", T: Setting },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.ListSettingsRequest
 */
export const ListSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.ListSettingsRequest",
  () => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "groupName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.ListSettingsResponse
 */
export const ListSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.ListSettingsResponse",
  () => [
    { no: 1, name: "settings", kind: "message", T: Setting, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.EditSettingRequest
 */
export const EditSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.EditSettingRequest",
  () => [
    { no: 1, name: "groupName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "setting", kind: "message", T: Setting },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.EditSettingResponse
 */
export const EditSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.EditSettingResponse",
  [],
);

/**
 * @generated from message settings_manager.v1.admin_settings.DeleteSettingRequest
 */
export const DeleteSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.DeleteSettingRequest",
  () => [
    { no: 1, name: "settingID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "groupName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.admin_settings.DeleteSettingResponse
 */
export const DeleteSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.admin_settings.DeleteSettingResponse",
  [],
);

