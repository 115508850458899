import type { Provider } from '@angular/core';
import { InjectionToken } from '@angular/core';

import { provideValue } from '@pu/sdk';

import { PaginationType } from '../pagination.model';
import { PAGINATION_TYPE } from '../pagination.providers';
import { provideDefaultPaginationManager } from '../pagination-management/default-pagination/default-pagination.providers';
import type { PaginationManager } from '../pagination-management/pagination-management.model';
import type { PaginationPanelFeatures } from './features/models';
import { DEFAULT_PAGINATION } from './pagination.const';
import type { PaginationPanelPayload } from './pagination-panel.model';

export const PAGINATION_PANEL_MANAGER = new InjectionToken<
  PaginationManager<PaginationPanelPayload>
>('PAGINATION_PANEL_MANAGER');

export const PAGINATION_PANEL_INITIAL_STATE =
  new InjectionToken<PaginationPanelPayload>('PAGINATION_PANEL_INITIAL_STATE', {
    factory: () => DEFAULT_PAGINATION,
  });

export const providePaginationPanel = (
  ...features: PaginationPanelFeatures[]
): Provider[] => [
  provideValue<PaginationType>(PAGINATION_TYPE, PaginationType.Panel),
  provideDefaultPaginationManager<PaginationPanelPayload>(
    PAGINATION_PANEL_MANAGER,
    PAGINATION_PANEL_INITIAL_STATE,
  ),
  features.map(({ providers }) => providers),
];
