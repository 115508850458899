import type { ValueEqualityFn } from '@angular/core';
import { Directive, inject, input } from '@angular/core';
import { defaultEquals } from '@angular/core/primitives/signals';
import { NgControl } from '@angular/forms';

import { toggleArrayValue } from '../toggle-array-value';

@Directive({
  selector: '[controlMultiSelect]',
  standalone: true,
  exportAs: 'controlMultiSelect',
})
export class ControlMultiSelectDirective<T> {
  public equals = input<ValueEqualityFn<T>>(defaultEquals, {
    alias: 'controlMultiSelectEqual',
  });

  private readonly ngControl = inject(NgControl, { self: true });

  public toggle(item: T): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.ngControl.control!.patchValue(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      toggleArrayValue(this.ngControl.value ?? [], item, this.equals()),
    );
  }

  // impossible to infer control value type, must use 'any'
  get value(): any[] | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.ngControl.value ?? null;
  }
}
