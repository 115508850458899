import { Int64Range, SumRange, TimeRange } from '@pinup-grpc/base/filter_pb';
import { SumRange as StatSumRange } from '@pinup-grpc/pinup/stat/report/base/filtering_pb';
import { endOfDay, getTime, startOfDay } from 'date-fns';

import { stringToBigIntOrUndefined } from '@pu/sdk';
import { stringToKopecks, toKopecksOrUndefined, toTimestamp } from '@bo/common';

import { DEFAULT_CONVERT_CURRENCY } from '../../constants/common.const';

export function toTimeRange(
  fromValue: Date | string | null | undefined,
  toValue: Date | string | null | undefined,
): TimeRange {
  const from = fromValue ? getTime(startOfDay(new Date(fromValue))) : null;
  const to = toValue ? getTime(endOfDay(new Date(toValue))) : null;

  return new TimeRange({
    from: toTimestamp(from) ?? undefined,
    to: toTimestamp(to) ?? undefined,
  });
}

export function toSumRange(
  from: string | null | undefined,
  to: string | null | undefined,
): SumRange {
  return new SumRange({
    from: stringToKopecks(from),
    to: stringToKopecks(to),
  });
}

// TODO: refactor and remove after
export function toSumRangeOrUndefined(
  from: string | null | undefined,
  to: string | null | undefined,
): SumRange {
  return new SumRange({
    from: toKopecksOrUndefined(stringToBigIntOrUndefined(from)),
    to: toKopecksOrUndefined(stringToBigIntOrUndefined(to)),
  });
}

// different SumRange proto obj from toSumRangeOrUndefined
export function toSumRangeOrUndefinedWithCurrency(
  from: string | null | undefined,
  to: string | null | undefined,
  currency: string = DEFAULT_CONVERT_CURRENCY,
): StatSumRange {
  return new StatSumRange({
    from: toKopecksOrUndefined(stringToBigIntOrUndefined(from)),
    to: toKopecksOrUndefined(stringToBigIntOrUndefined(to)),
    currency,
  });
}

// TODO: refactor and remove after
export function toSumRangeOrUndefinedWithoutKopecks(
  from: string | null | undefined,
  to: string | null | undefined,
): SumRange {
  return new SumRange({
    from: stringToBigIntOrUndefined(from),
    to: stringToBigIntOrUndefined(to),
  });
}

export function toInt64Range(
  from: string | null | undefined,
  to: string | null | undefined,
  currency: string = DEFAULT_CONVERT_CURRENCY,
): Int64Range {
  const range = new Int64Range();

  range.from = stringToKopecks(from);
  range.to = stringToKopecks(to);
  range.currency = currency;

  return range;
}
