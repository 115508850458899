<div class="flex justify-end gap-3 border-bottom py-3 px-6">
  <bo-period-picker (dateRangeChange)="changeDateRange($event)" />

  <button mat-stroked-button (click)="openCreateNoteDialog()" type="button">
    <bo-svg [unsafeSvgSource]="plusIcon" />
    Add note
  </button>
</div>

<div class="p-6">
  @if (pinnedTotal$ | async; as total) {
    <div class="border-bottom pb-4">
      <div class="flex align-items-center gap-2 mb-2">
        <bo-svg class="label-color" [unsafeSvgSource]="chevronDownIcon" />
        <div class="text-subtitle">Pinned</div>
        <div class="badge">{{ pinnedTotal$ | async }}</div>
      </div>

      <div class="flex column gap-3">
        @for (note of pinnedNotes$ | async; track note.id) {
          <bo-note
            (edit)="openEditNoteDialog(note)"
            (pinnedToggle)="toggleNotePinned(note.id)"
            [note]="note"
            pinned
          />
        }
      </div>
    </div>
  }

  <div class="pt-4">
    <div class="flex column gap-3">
      @for (note of unpinnedNotes$ | async; track note.id) {
        <bo-note
          (edit)="openEditNoteDialog(note)"
          (pinnedToggle)="toggleNotePinned(note.id)"
          [note]="note"
        />
      }
    </div>
  </div>

  @if (unpinnedTotal$ | async; as totalInfo) {
    @if (pagination$ | async; as pagination) {
      @if (totalInfo.totalPages! >= 1) {
        <bo-pagination-panel
          (paginationChange)="setPagination($event)"
          [pagination]="pagination"
          [totalItems]="totalInfo.totalItems"
          [totalPages]="totalInfo.totalPages"
        />
      } @else {
        <bo-empty-template [isError]="true" message="No notes found" />
      }
    }
  }
</div>
