<div class="form-group p-3" [formGroup]="form()">
  <mat-form-field>
    <mat-label>Payment number</mat-label>
    <input
      aria-label="payment ids"
      [attr.data-testid]="'paymentNumber'"
      formControlName="paymentNumber"
      matInput
      placeholder="Type here"
      type="text"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>External ids</mat-label>
    <input
      aria-label="External ids"
      [attr.data-testid]="'externalIds'"
      formControlName="externalIds"
      matInput
      placeholder="Type here"
      type="text"
    />
  </mat-form-field>

  @if (paymentMethods$ | async; as methods) {
    <label class="form-label">Method</label>
    <mat-form-field>
      <mat-label>Include</mat-label>
      <mat-select
        aria-label="Payment method include"
        [attr.data-testid]="'methodInclude'"
        formControlName="methodInclude"
        multiple
        placeholder="All"
      >
        @for (item of methods; track item.id) {
          <mat-option [value]="item.id">{{ item.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Exclude</mat-label>
      <mat-select
        aria-label="Method exclude"
        [attr.data-testid]="'methodExclude'"
        formControlName="methodExclude"
        multiple
        placeholder="Select an option"
      >
        @for (item of methods; track item.id) {
          <mat-option [value]="item.id">{{ item.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  <div class="form-item">
    <label class="form-label">Chargeback</label>
    <bo-radio-toggle-bar
      class="radio-toggles"
      [attr.data-testid]="'isChargeback'"
      formControlName="isChargeback"
    >
      @for (option of yesNoOptions; track option.value) {
        <button
          bo-radio-toggle-item
          class="w-100"
          [value]="option.value"
          type="button"
        >
          {{ option.name }}
        </button>
      }
    </bo-radio-toggle-bar>
  </div>

  <div class="form-group">
    <label class="form-label mt-2 mb-1">Amount</label>
    <mat-form-field>
      <mat-label>Currency</mat-label>
      <input
        class="input-autocomplete"
        [attr.data-testid]="'amountCurrency'"
        [matAutocomplete]="amountAuto"
        formControlName="amountCurrency"
        matInput
        placeholder="All currencies…"
        #amountSearchInput
      />
      <mat-autocomplete
        autoActiveFirstOption
        requireSelection
        #amountAuto="matAutocomplete"
      >
        @for (
          item of currencies$
            | async
            | autocomplete: currencyAutocomplete : amountSearchInput.value;
          track item.value
        ) {
          <mat-option [value]="item.value.code">
            <bo-highlight-match
              [match]="item.match"
              [search]="amountSearchInput.value"
            />
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <div class="form-row">
      <mat-form-field>
        <mat-label>From</mat-label>
        <input
          aria-label="Amount from"
          [attr.data-testid]="'amountFrom'"
          formControlName="amountFrom"
          matInput
          placeholder="Type here"
          type="text"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Until</mat-label>
        <input
          aria-label="Amount to"
          [attr.data-testid]="'amountTo'"
          formControlName="amountTo"
          matInput
          placeholder="Type here"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>

  <mat-form-field>
    <mat-label>Created</mat-label>
    <mat-date-range-input [rangePicker]="createdAt">
      <input formControlName="createdFrom" matStartDate placeholder="From" />
      <input formControlName="createdTo" matEndDate placeholder="To" />
    </mat-date-range-input>
    <mat-datepicker-toggle [for]="createdAt" matIconSuffix />
    <mat-date-range-picker #createdAt />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Updated</mat-label>
    <mat-date-range-input [rangePicker]="updatedAt">
      <input formControlName="updatedFrom" matStartDate placeholder="From" />
      <input formControlName="updatedTo" matEndDate placeholder="To" />
    </mat-date-range-input>
    <mat-datepicker-toggle [for]="updatedAt" matIconSuffix />
    <mat-date-range-picker #updatedAt />
  </mat-form-field>

  <div class="form-item">
    <label class="form-label">First hour deposits</label>
    <bo-radio-toggle-bar
      class="radio-toggles"
      [attr.data-testid]="'isFistHourDeposit'"
      formControlName="isFistHourDeposit"
    >
      @for (option of yesNoOptions; track option.value) {
        <button
          bo-radio-toggle-item
          class="w-100"
          [value]="option.value"
          type="button"
        >
          {{ option.name }}
        </button>
      }
    </bo-radio-toggle-bar>
  </div>

  <div class="form-item">
    <label class="form-label">First deposits</label>
    <bo-radio-toggle-bar
      class="radio-toggles"
      [attr.data-testid]="'isFirstDeposit'"
      formControlName="isFirstDeposit"
    >
      @for (option of yesNoOptions; track option.value) {
        <button
          bo-radio-toggle-item
          class="w-100"
          [value]="option.value"
          type="button"
        >
          {{ option.name }}
        </button>
      }
    </bo-radio-toggle-bar>
  </div>

  <!--  <mat-checkbox-->
  <!--    [attr.data-testid]="'isFistHourDeposit'"-->
  <!--    formControlName="isFistHourDeposit"-->
  <!--  >-->
  <!--    First hour deposits-->
  <!--  </mat-checkbox>-->
  <!--  <mat-checkbox-->
  <!--    [attr.data-testid]="'isFirstDeposit'"-->
  <!--    formControlName="isFirstDeposit"-->
  <!--  >-->
  <!--    First deposits-->
  <!--  </mat-checkbox>-->

  <label class="form-label mt-2 mb-1">Balance</label>
  <mat-form-field>
    <mat-label>Currency</mat-label>
    <input
      class="input-autocomplete"
      [attr.data-testid]="'amountCurrency'"
      [matAutocomplete]="balanceAutoComplete"
      formControlName="amountCurrency"
      matInput
      placeholder="All currencies…"
      #balanceSearchInput
    />
    <mat-autocomplete
      autoActiveFirstOption
      requireSelection
      #balanceAutoComplete="matAutocomplete"
    >
      @for (
        item of currencies$
          | async
          | autocomplete: currencyAutocomplete : balanceSearchInput.value;
        track item.value
      ) {
        <mat-option [value]="item.value.code">
          <bo-highlight-match
            [match]="item.match"
            [search]="balanceSearchInput.value"
          />
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <label class="form-label">Before</label>
  <div class="form-row">
    <mat-form-field>
      <mat-label>From</mat-label>
      <input
        aria-label="Balance before from"
        [attr.data-testid]="'balanceBeforeFrom'"
        formControlName="balanceBeforeFrom"
        matInput
        placeholder="Type here"
        type="text"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Until</mat-label>
      <input
        aria-label="Balance before to"
        [attr.data-testid]="'balanceBeforeTo'"
        formControlName="balanceBeforeTo"
        matInput
        placeholder="Type here"
        type="text"
      />
    </mat-form-field>
  </div>
  <label class="form-label">After</label>
  <div class="form-row">
    <mat-form-field>
      <mat-label>From</mat-label>
      <input
        aria-label="Balance until from"
        [attr.data-testid]="'balanceFrom'"
        formControlName="balanceFrom"
        matInput
        placeholder="Type here"
        type="text"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Until</mat-label>
      <input
        aria-label="Balance until to"
        [attr.data-testid]="'balanceTo'"
        formControlName="balanceTo"
        matInput
        placeholder="Type here"
        type="text"
      />
    </mat-form-field>
  </div>
</div>
