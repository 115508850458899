import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import { SvgComponent } from '@pu/sdk';

import { filterClearIcon, filterIcon } from '../../icons';
import type { FiltersForm } from '../filters.form';

@Component({
  selector: 'bo-global-filled-filters-count',
  templateUrl: './global-filled-filters-count.component.html',
  styleUrl: './global-filled-filters-count.component.scss',
  standalone: true,
  imports: [SvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalFilledFiltersCountComponent<T extends {}> {
  public form = input.required<FiltersForm<T>>();

  protected readonly filterIcon = filterIcon;
  protected readonly filterClearIcon = filterClearIcon;

  protected readonly count = computed(() => this.form().count());
  protected readonly hasFilledCount = computed(() =>
    this.form().hasCounterValue(),
  );
}
