import { inject, Injectable } from '@angular/core';
import type { Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import type { Observable } from 'rxjs';
import { catchError, map, of, shareReplay, startWith, switchMap } from 'rxjs';

import { DocumentRemoteService } from '@pu/grpc';
import { getFileType } from '@bo/common';

import { patchTaxIdDocuments } from '../utils/document-verification';

@Injectable()
export class DocumentsManagementService {
  private readonly documentsRemoteService = inject(DocumentRemoteService);
  private readonly route = inject(ActivatedRoute);

  private readonly playerId$ = this.route.params.pipe(
    map<Params, bigint>(({ playerId }) => BigInt(Number(playerId))),
  );

  public readonly playerDocuments$ = this.playerId$.pipe(
    switchMap((playerId) => {
      return this.documentsRemoteService.getPlayerDocuments({
        playerId,
      });
    }),
    shareReplay(1),
  );

  public readonly documents$ = this.playerDocuments$.pipe(
    map(({ documents }) => documents),
    map(patchTaxIdDocuments),
    shareReplay(1),
  );

  public readonly total$ = this.documents$.pipe(
    map((documents) => documents.length),
  );

  public isLoading$ = this.playerDocuments$.pipe(
    map(() => false),
    catchError(() => of(false)),
    startWith(true),
  );

  public readonly hasData$ = this.playerDocuments$.pipe(
    map((response) => response.documents.length > 0),
  );

  public readonly hasError$ = this.playerDocuments$.pipe(
    map(() => false),
    catchError(() => of(true)),
  );

  public getDocumentImage(name: string): Observable<Blob> {
    return this.documentsRemoteService
      .getDocumentImage({ name })
      .pipe(
        map(
          ({ image }) => new File([image], name, { type: getFileType(name) }),
        ),
      );
  }
}
