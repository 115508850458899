import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { isNil } from 'lodash-es';

export interface MatchResult {
  readonly text: string;
  readonly index: number;
}

export interface MatchItem<T> {
  match: MatchResult;
  value: T;
}

@Pipe({
  name: 'autocomplete',
  standalone: true,
})
export class AutocompletePipe implements PipeTransform {
  public transform<T>(
    list: Iterable<T> | null | undefined,
    equal: (value: T, search: string) => MatchResult,
    search: string,
  ): readonly MatchItem<T>[] {
    if (isNil(list)) {
      return [];
    }

    const result: MatchItem<T>[] = [];

    for (const value of list) {
      const match = equal(value, search);

      if (match.index === -1) {
        continue;
      }

      result.push({ match, value });
    }

    result.sort((a, b) => a.match.index - b.match.index);

    return result;
  }
}
