import { inject, Injectable } from '@angular/core';
import type { Transaction } from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import {
  combineLatest,
  map,
  shareReplay,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import type {
  FiltersProvider,
  PlayerBalanceHistoryFilter,
  ProfileBalanceHistoryVM,
} from '@bo/common';
import {
  FILTERS_PROVIDER,
  getStatus,
  PAGINATION_PANEL_MANAGER,
  PlayerService,
  prepareFinance,
  TransactionsProvider,
} from '@bo/common';

@Injectable()
export class PlayerBalanceHistoryService {
  public transactionsProvider = inject(TransactionsProvider);

  private readonly pagination$ = inject(PAGINATION_PANEL_MANAGER).source$;

  public readonly playerId$ = inject(PlayerService).playerId$;

  private readonly filterService =
    inject<FiltersProvider<PlayerBalanceHistoryFilter>>(FILTERS_PROVIDER);
  public readonly filters$ = this.filterService.filters$;

  public actions$ = combineLatest([this.pagination$, this.filters$]);

  public rawTransactions$ = this.actions$.pipe(
    withLatestFrom(this.playerId$),
    switchMap(([[pagination, filters], playerId]) =>
      this.transactionsProvider.getTransactions(pagination, {
        playerIds: [playerId],
        ...filters,
      }),
    ),
    shareReplay(1),
  );

  public total$ = this.rawTransactions$.pipe(
    map(({ pagination: { totalPages, totalItems } }) => ({
      totalPages,
      totalItems,
    })),
  );

  public transactions$ = this.rawTransactions$.pipe(
    map(({ transactions }) =>
      PlayerBalanceHistoryService.normalizeTransactions(transactions),
    ),
  );

  private static normalizeTransactions(
    transactions: Transaction[],
  ): ProfileBalanceHistoryVM[] {
    return transactions.map(
      (transaction): ProfileBalanceHistoryVM => ({
        ...transaction,
        finance: prepareFinance(transaction.finance),
        convertFinance: prepareFinance(transaction.convertFinance),
        status: getStatus(transaction),
      }),
    );
  }
}
