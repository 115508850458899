<button class="filters-count">
  <bo-svg class="filter-count-icon" [unsafeSvgSource]="filterIcon" />

  <span class="filters-count-text">Filter</span>

  @if (hasFilledCount()) {
    <span class="filters-count-value">{{ count() }}</span>
  }
</button>

@if (hasFilledCount()) {
  <bo-svg
    class="filters-clear"
    (click)="$event.stopPropagation(); form().reset(form().defaultValue())"
    [unsafeSvgSource]="filterClearIcon"
  />
}
