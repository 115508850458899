// @generated by protoc-gen-es v1.10.0
// @generated from file settings_manager/v1/models/groups.proto (package settings_manager.v1.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp, Value } from "@bufbuild/protobuf";

/**
 * @generated from enum settings_manager.v1.models.Type
 */
export const Type = /*@__PURE__*/ proto3.makeEnum(
  "settings_manager.v1.models.Type",
  [
    {no: 0, name: "TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TYPE_INT", localName: "INT"},
    {no: 2, name: "TYPE_FLOAT", localName: "FLOAT"},
    {no: 3, name: "TYPE_BOOL", localName: "BOOL"},
    {no: 4, name: "TYPE_OBJECT", localName: "OBJECT"},
    {no: 5, name: "TYPE_ARRAY", localName: "ARRAY"},
    {no: 6, name: "TYPE_STRING", localName: "STRING"},
  ],
);

/**
 * @generated from message settings_manager.v1.models.Group
 */
export const Group = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.models.Group",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "schema", kind: "message", T: Schema, repeated: true },
    { no: 3, name: "deletedAt", kind: "message", T: Timestamp },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.models.Schema
 */
export const Schema = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.models.Schema",
  () => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Type) },
    { no: 3, name: "defaultValue", kind: "message", T: Value },
    { no: 4, name: "validations", kind: "message", T: SchemaValidations },
    { no: 5, name: "deletedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message settings_manager.v1.models.SchemaValidations
 */
export const SchemaValidations = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.models.SchemaValidations",
  () => [
    { no: 1, name: "require", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "minLen", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "maxLen", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "options", kind: "message", T: Value, repeated: true },
  ],
);

