import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { CurrentRouteNavigationService } from '@pu/sdk';
import {
  FiltersComponent,
  FiltersFormWrapperComponent,
  financeIcon,
  GlobalFilledFiltersCountComponent,
  GlobalFiltersTransferDirective,
  PageLayoutComponent,
  PlayerService,
  provideFiltersFormResolver,
  usersIcon,
} from '@bo/common';

import type { FinanceDepositsFilter } from './deposits-filter.types';
import { DepositsFilterResolverService } from './deposits-filter-resolver.service';
import type { DepositsFilters } from './forms';
import { FinanceDepositsFilterForm } from './forms';
import type { DepositsFilterFinanceForm } from './forms/finance-filter.form';
import { DepositFinanceFilterComponent } from './forms/finance-filter/finance-filter.component';
import type { DepositsFilterOtherForm } from './forms/other-filter.form';
import { DepositOtherFilterComponent } from './forms/other-filter/other-filter.component';

@Component({
  selector: 'bo-player-transactions-real-balance-deposits-filter',
  standalone: true,
  imports: [
    FiltersFormWrapperComponent,
    DepositFinanceFilterComponent,
    DepositOtherFilterComponent,
    GlobalFilledFiltersCountComponent,
    GlobalFiltersTransferDirective,
  ],
  providers: [
    provideFiltersFormResolver(DepositsFilterResolverService),
    CurrentRouteNavigationService,
  ],
  templateUrl:
    './player-transactions-real-balance-deposits-filter.component.html',
  styleUrl: './player-transactions-real-balance-deposits-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerTransactionsRealBalanceDepositsFilterComponent extends FiltersComponent<
  DepositsFilters,
  FinanceDepositsFilter
> {
  public readonly layout = inject(PageLayoutComponent);
  private readonly player$ = inject(PlayerService).player$;

  protected readonly usersIcon = usersIcon;
  protected readonly financeIcon = financeIcon;

  public form = new FinanceDepositsFilterForm();

  public readonly financeForm = this.form.controls
    .finance as DepositsFilterFinanceForm;
  public readonly otherForm = this.form.controls
    .other as DepositsFilterOtherForm;

  constructor() {
    super();

    this.player$.pipe(takeUntilDestroyed()).subscribe((player) => {
      const defaultValue = {
        finance: { amountCurrency: player.finance.deposits.currency },
      };
      this.form.setDefaultValue(defaultValue);
      this.form.patchValue(defaultValue);
    });
  }
}
