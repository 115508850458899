import {
  omitEmptyObjectProps,
  parseStringToArray,
  stringToBigInt,
  toBigintArray,
  toStringMultiselect,
  toUint64Multiselect,
} from '@pu/sdk';
import type { PersistentData } from '@bo/common';
import {
  fromDichotomy,
  toDichotomy,
  toFormDateFromString,
  toTimeRange,
} from '@bo/common';

import type { ListDepositsFilter } from './deposits-filter.types';
import { toSumRange } from './deposits-filter.utils';
import type { DepositsFilters } from './forms';

export const toFormData = (params: PersistentData): DepositsFilters => ({
  finance: {
    paymentNumber: toBigintArray(params.paymentNumber),
    externalIds: parseStringToArray(params.externalIds),
    methodInclude: parseStringToArray(params.methodInclude, stringToBigInt),
    methodExclude: parseStringToArray(params.methodExclude, stringToBigInt),
    amountCurrency: params.amountCurrency,
    amountFrom: stringToBigInt(params.amountFrom),
    amountTo: stringToBigInt(params.amountTo),
    createdFrom: toFormDateFromString(params.createdFrom),
    createdTo: toFormDateFromString(params.createdTo),
    updatedFrom: toFormDateFromString(params.updatedFrom),
    updatedTo: toFormDateFromString(params.updatedTo),
    isFirstDeposit: toDichotomy(params.isFirstDeposit),
    isFistHourDeposit: toDichotomy(params.isFistHourDeposit),
    balanceCurrency: params.balanceCurrency,
    balanceFrom: stringToBigInt(params.balanceFrom),
    balanceTo: stringToBigInt(params.balanceTo),
    balanceBeforeFrom: stringToBigInt(params.balanceBeforeFrom),
    balanceBeforeTo: stringToBigInt(params.balanceBeforeTo),
    isChargeback: toDichotomy(params.isChargeback),
  },
  other: {
    domain: params.domain || '',
    playerGeo: parseStringToArray(params.playerGeo),
    clickId: params.clickId,
  },
});

export const toModelData = (
  params: PersistentData,
): Partial<ListDepositsFilter> =>
  omitEmptyObjectProps<ListDepositsFilter>({
    profile: {
      // here data will be set in service before send
      ids: [],
      clickId: params.clickId,
      balanceBefore: toSumRange(
        params.balanceBeforeFrom,
        params.balanceBeforeTo,
        params.balanceCurrency,
      ),
      balance: toSumRange(
        params.balanceFrom,
        params.balanceTo,
        params.balanceCurrency,
      ),
      playerGeo: toStringMultiselect(params.playerGeo),
    },
    deposit: {
      ids: toBigintArray(params.paymentNumber),
      externalIds: params.externalIds?.split(','),
      paymentMethod: toUint64Multiselect(
        params.methodInclude,
        params.methodExclude,
      ),
      amount: toSumRange(
        params.amountFrom,
        params.amountTo,
        params.amountCurrency,
      ),
      // durationToCompletedRange
      isFirstDeposit: fromDichotomy(params.isFirstDeposit),
      isFistHourDeposit: fromDichotomy(params.isFistHourDeposit),
      domain: params.domain,
      createdAt: toTimeRange(params.createdFrom, params.createdTo),
      updatedAt: toTimeRange(params.updatedFrom, params.updatedTo),
      isChargeback: fromDichotomy(params.isChargeback),
    },
  });
