// @generated by protoc-gen-es v1.10.0
// @generated from file services/bo/transactions/transactions.proto (package stat.bo.transactions, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Withdrawal, WithdrawalPaymentStatus, WithdrawalStatus } from "../withdrawals/withdrawals_pb.js";
import { SumRange, TimeRange } from "../../../base/filter_pb.js";
import { PaginationRequest, PaginationResponse } from "../../../base/pagination_pb.js";
import { Sorting } from "../../../base/sorting_pb.js";

/**
 * @generated from enum stat.bo.transactions.TransactionType
 */
export const TransactionType = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.transactions.TransactionType",
  [
    {no: 0, name: "TRANSACTION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_TYPE_WITHDRAWAL", localName: "WITHDRAWAL"},
    {no: 2, name: "TRANSACTION_TYPE_DEPOSIT", localName: "DEPOSIT"},
    {no: 3, name: "TRANSACTION_TYPE_CASINO_BET", localName: "CASINO_BET"},
    {no: 4, name: "TRANSACTION_TYPE_SPORT_BET", localName: "SPORT_BET"},
    {no: 5, name: "TRANSACTION_TYPE_REAL_BONUS", localName: "REAL_BONUS"},
  ],
);

/**
 * CasinoTransactions is based model of game-aggregator /apis/pinup/game-aggregator/events/TournamentBet
 * is deleted=false
 *
 * @generated from message stat.bo.transactions.CasinoTransaction
 */
export const CasinoTransaction = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.CasinoTransaction",
  [],
);

/**
 * SportTransaction is based model of digitain /apis/pinup/digitain/common/BetTransaction
 *
 * @generated from message stat.bo.transactions.SportTransaction
 */
export const SportTransaction = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.SportTransaction",
  [],
);

/**
 * @generated from message stat.bo.transactions.Deposit
 */
export const Deposit = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.Deposit",
  () => [
    { no: 1, name: "paymentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "paymentAggregatorId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "paymentMethodId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "paymentSystemId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "createdAt", kind: "message", T: Timestamp },
  ],
);

/**
 * RealBonusTransaction is based model of billing /apis/pinup/billing/events/TransactionEvent
 *
 * @generated from message stat.bo.transactions.RealBonusTransaction
 */
export const RealBonusTransaction = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.RealBonusTransaction",
  [],
);

/**
 * @generated from message stat.bo.transactions.Transaction
 */
export const Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.Transaction",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "Id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "time", kind: "message", T: Timestamp },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(TransactionType) },
    { no: 5, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "deposit", kind: "message", T: Deposit, oneof: "transaction" },
    { no: 11, name: "withdrawal", kind: "message", T: Withdrawal, oneof: "transaction" },
    { no: 12, name: "casinoTransaction", kind: "message", T: CasinoTransaction, oneof: "transaction" },
    { no: 13, name: "sportTransaction", kind: "message", T: SportTransaction, oneof: "transaction" },
    { no: 14, name: "realBonusTransaction", kind: "message", T: RealBonusTransaction, oneof: "transaction" },
  ],
);

/**
 * @generated from message stat.bo.transactions.ListTransactionsRequest
 */
export const ListTransactionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.ListTransactionsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "period", kind: "message", T: TimeRange },
    { no: 3, name: "filter", kind: "message", T: ListTransactionsRequest_Filter },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 5, name: "sorting", kind: "message", T: Sorting, repeated: true },
  ],
);

/**
 * @generated from message stat.bo.transactions.ListTransactionsRequest.Filter
 */
export const ListTransactionsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.ListTransactionsRequest.Filter",
  () => [
    { no: 1, name: "transactionTypes", kind: "enum", T: proto3.getEnumType(TransactionType), repeated: true },
    { no: 2, name: "transactionIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "amount", kind: "message", T: SumRange },
    { no: 4, name: "withdrawalStatuses", kind: "enum", T: proto3.getEnumType(WithdrawalStatus), repeated: true },
    { no: 5, name: "paymentStatuses", kind: "enum", T: proto3.getEnumType(WithdrawalPaymentStatus), repeated: true },
  ],
  {localName: "ListTransactionsRequest_Filter"},
);

/**
 * @generated from message stat.bo.transactions.ListTransactionsResponse
 */
export const ListTransactionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.transactions.ListTransactionsResponse",
  () => [
    { no: 1, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

