import { Directive, inject, input } from '@angular/core';
import type { Sort } from '@angular/material/sort';

import type { GetPlayerPaymentMethodsResponse } from '@pu/grpc';
import { extractEnumValues } from '@pu/sdk';
import {
  EMPTY_TEXT_PLACEHOLDER,
  type Pagination,
  PAGINATION_PANEL_MANAGER,
  PlayerPaymentMethodService,
  questionCircle,
  toSortingOrder,
  TRANSACTION_TOOLTIPS,
} from '@bo/common';

enum Column {
  GEO = 'geo',
  DEPOSIT = 'deposit.sumSuccessful',
  WITHDRAWAL = 'withdrawal.sumSuccessful',
  PSP = 'psp',
  LAST_ACTIVITY = 'lastActivityAt',
}

@Directive()
export class PaymentTableComponent {
  public paymentMethods =
    input.required<GetPlayerPaymentMethodsResponse | null>();
  protected readonly EMPTY_TEXT_PLACEHOLDER = EMPTY_TEXT_PLACEHOLDER;

  private readonly paginationManager = inject(PAGINATION_PANEL_MANAGER);
  private readonly playerPaymentMethodService = inject(
    PlayerPaymentMethodService,
  );

  protected readonly pagination$ = this.paginationManager.source$;
  protected readonly total$ = this.playerPaymentMethodService.total$;
  protected readonly hasError$ = this.playerPaymentMethodService.hasError$;
  protected readonly isLoading$ = this.playerPaymentMethodService.isLoading$;
  protected readonly hasData$ = this.playerPaymentMethodService.hasData$;

  protected readonly Column = Column;
  protected readonly columns = [...extractEnumValues(Column)];
  protected readonly transactionTooltips = TRANSACTION_TOOLTIPS;
  protected readonly questionCircle = questionCircle;

  public setPagination(pagination: Pagination): void {
    this.paginationManager.update(pagination);
  }

  protected changeSort({ active, direction }: Sort): void {
    this.playerPaymentMethodService.changeSort({
      fieldName: active,
      order: toSortingOrder(direction),
    });
  }
}
