import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DEFAULT_PAGINATION_TOTAL } from './total-info.const';
import type { PaginationTotal } from './total-info.model';

@Injectable()
export class PaginationTotalManagerService {
  private readonly _total$ = new BehaviorSubject<PaginationTotal>(
    DEFAULT_PAGINATION_TOTAL,
  );

  public readonly total$ = this._total$.asObservable();

  public updateTotal(value: PaginationTotal): void {
    this._total$.next(value);
  }
}
