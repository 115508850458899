import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { GoToPaginationComponent } from './go-to-pagination/go-to-pagination.component';
import { PageSizeSelectComponent } from './page-size-select/page-size-select.component';
import { PaginationComponent } from './pagination.component';
import { DEFAULT_PAGINATION } from './pagination.const';
import type { Pagination } from './pagination-panel.model';

@Component({
  selector: 'bo-pagination-panel',
  templateUrl: './pagination-panel.component.html',
  styleUrls: ['./pagination-panel.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PageSizeSelectComponent,
    PaginationComponent,
    GoToPaginationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationPanelComponent {
  @Output() public readonly pageChange = new EventEmitter<number>();
  @Output() public readonly pageSizeChange = new EventEmitter<number>();
  @Output() public readonly paginationChange = new EventEmitter<Pagination>();

  @Input()
  get pagination(): Pagination {
    return this.#pagination;
  }

  set pagination(value: Partial<Pagination>) {
    this.updatePagination(value);
  }

  @Input()
  set pageSize(pageSize: number) {
    this.handlePageSizeChange(pageSize);
  }

  @Input()
  set currentPage(currentPage: number) {
    this.handleCurrentPageChange(currentPage);
  }

  @Input()
  set totalItems(totalItems: number) {
    this.updatePagination({ totalItems });
  }

  @Input()
  set totalPages(totalPages: number) {
    this.updatePagination({ totalPages });
  }

  #pagination = DEFAULT_PAGINATION;

  public updatePagination(
    pagination: Partial<Pagination>,
    propagateChange = false,
  ): void {
    this.#pagination = { ...this.#pagination, ...pagination };

    if (propagateChange) {
      this.paginationChange.emit(this.pagination);
    }
  }

  public handleCurrentPageChange(
    currentPage: number,
    propagateChange = false,
  ): void {
    this.updatePagination({ currentPage }, propagateChange);

    if (propagateChange) {
      this.pageChange.emit(currentPage);
    }
  }

  public handlePageSizeChange(pageSize: number, propagateChange = false): void {
    // Reset current page on page size change
    this.updatePagination({ pageSize, currentPage: 1 }, propagateChange);

    if (propagateChange) {
      this.pageSizeChange.emit(pageSize);
    }
  }
}
