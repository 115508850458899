import {
  ChangeDetectionStrategy,
  Component,
  input,
  model,
} from '@angular/core';

import { chevronDownIcon, chevronRightIcon, SvgComponent } from '@pu/sdk';

@Component({
  selector: 'bo-toggle-wrapper',
  standalone: true,
  imports: [SvgComponent],
  templateUrl: './toggle-wrapper.component.html',
  styleUrl: './toggle-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleWrapperComponent {
  public titleContent = input.required<string>();
  public titleCounter = input<number | null>();

  protected readonly chevronExpanded = chevronDownIcon;
  protected readonly chevronClosed = chevronRightIcon;

  public isExpanded = model<boolean>(true);

  public toggle(): void {
    this.isExpanded.update((isExpanded) => !isExpanded);
  }
}
