import type { MaskitoOptions } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

export const DECIMAL_MASK_OPTIONS = maskitoNumberOptionsGenerator({
  decimalSeparator: '.',
  precision: 2,
  thousandSeparator: ',',
});

export const NON_DECIMAL_MASK_OPTIONS = maskitoNumberOptionsGenerator({
  precision: 0,
  min: 0,
  thousandSeparator: '',
});

export const DECIMAL_WITHOUT_THOUSAND_SEPARATOR_MASK_OPTIONS =
  maskitoNumberOptionsGenerator({
    decimalSeparator: '.',
    precision: 2,
    thousandSeparator: '',
  });

export enum MaskitoFields {
  CPF = 'cpf',
}
export const MASK_MAP: Record<string, MaskitoOptions> = {
  [MaskitoFields.CPF]: {
    // prettier-ignore
    mask: [
      /\d/, /\d/, /\d/,
      '.',
      /\d/, /\d/, /\d/,
      '.',
      /\d/, /\d/, /\d/,
      '-',
      /\d/, /\d/,
    ],
  },
};
