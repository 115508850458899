<div class="header" (click)="toggle()">
  <bo-svg
    class="icon"
    [size]="24"
    [unsafeSvgSource]="isExpanded() ? chevronExpanded : chevronClosed"
  />
  <span class="text">{{ titleContent() }}</span>
  @if (titleCounter()) {
    <div class="counter">
      {{ titleCounter() }}
    </div>
  }
</div>

@if (isExpanded()) {
  <div class="content">
    <ng-content />
  </div>
}
