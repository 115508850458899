import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import type { Observable } from 'rxjs';

import type { PlayerNote } from '@pu/grpc';
import { plusIcon, SvgComponent } from '@pu/sdk';
import type { DateRange, Pagination } from '@bo/common';
import {
  chevronDownIcon,
  EmptyTemplateComponent,
  PAGINATION_PANEL_MANAGER,
  PaginationPanelComponent,
  PeriodPickerComponent,
} from '@bo/common';

import { NoteComponent } from './note/note.component';
import { PlayerNotesService } from './player-notes.service';

@Component({
  selector: 'bo-player-notes',
  standalone: true,
  imports: [
    AsyncPipe,
    PaginationPanelComponent,
    NoteComponent,
    SvgComponent,
    MatButton,
    PeriodPickerComponent,
    EmptyTemplateComponent,
  ],
  templateUrl: './player-notes.component.html',
  styleUrl: './player-notes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerNotesComponent {
  private readonly notesService = inject(PlayerNotesService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly paginationManager = inject(PAGINATION_PANEL_MANAGER);

  protected pinnedNotes$: Observable<PlayerNote[]> =
    this.notesService.pinnedNotes$;
  protected pinnedTotal$ = this.notesService.pinnedTotal$;
  protected unpinnedNotes$: Observable<PlayerNote[]> =
    this.notesService.unpinnedNotes$;
  protected unpinnedTotal$ = this.notesService.unpinnedTotal$;
  protected pagination$ = this.paginationManager.source$;

  protected readonly plusIcon = plusIcon;
  protected readonly chevronDownIcon = chevronDownIcon;

  public setPagination(pagination: Pagination): void {
    this.paginationManager.update(pagination);
  }

  public toggleNotePinned(noteId: bigint): void {
    this.notesService
      .toggleNotePinned(noteId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  public openCreateNoteDialog(): void {
    this.notesService.openCreateNoteDialog().subscribe();
  }

  public openEditNoteDialog(note: PlayerNote): void {
    this.notesService.openEditNoteDialog(note).subscribe();
  }

  protected changeDateRange(dateRange?: DateRange): void {
    this.notesService.setCreatedAt(dateRange);
  }
}
