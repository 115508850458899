// @generated by protoc-gen-es v1.10.0
// @generated from file services/player_stat.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Action, Game, Method, Money, Project } from "../base/enum_pb.js";
import { Sorting } from "../base/sorting_pb.js";
import { PaginationRequest, PaginationResponse } from "../base/pagination_pb.js";
import { Int64Range, TimeRange } from "../base/filter_pb.js";
import { Error } from "../base/error_pb.js";
import { Sorting as Sorting$1 } from "../pinup/std/query/v1/sorting_pb.js";
import { PaginationRequest as PaginationRequest$1, PaginationResponse as PaginationResponse$1 } from "../pinup/std/query/v1/pagination_pb.js";

/**
 * Player statistic event
 *
 * @generated from message stat.PlayerEvent
 */
export const PlayerEvent = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerEvent",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "project", kind: "enum", T: proto3.getEnumType(Project) },
    { no: 4, name: "method", kind: "enum", T: proto3.getEnumType(Method) },
    { no: 5, name: "money", kind: "enum", T: proto3.getEnumType(Money) },
    { no: 6, name: "action", kind: "enum", T: proto3.getEnumType(Action) },
    { no: 7, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "createdAt", kind: "message", T: Timestamp },
    { no: 11, name: "gameType", kind: "enum", T: proto3.getEnumType(Game) },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.ListEventsRequest
 */
export const ListEventsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ListEventsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "filter", kind: "message", T: ListEventsRequest_Filter },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * empty filter means all values
 *
 * @generated from message stat.ListEventsRequest.Filter
 */
export const ListEventsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ListEventsRequest.Filter",
  () => [
    { no: 1, name: "period", kind: "message", T: TimeRange },
    { no: 2, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 3, name: "actions", kind: "enum", T: proto3.getEnumType(Action), repeated: true },
    { no: 4, name: "methods", kind: "enum", T: proto3.getEnumType(Method), repeated: true },
    { no: 5, name: "moneys", kind: "enum", T: proto3.getEnumType(Money), repeated: true },
    { no: 6, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "gameTypes", kind: "enum", T: proto3.getEnumType(Game), repeated: true },
    { no: 8, name: "sumRange", kind: "message", T: Int64Range },
  ],
  {localName: "ListEventsRequest_Filter"},
);

/**
 * Deprecated
 *
 * @generated from message stat.ListEventsResponse
 */
export const ListEventsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ListEventsResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: PlayerEvent, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.ListPlayersIdsRequest
 */
export const ListPlayersIdsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ListPlayersIdsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: ListPlayersIdsRequest_Filter },
    { no: 2, name: "limit", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "fromId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message stat.ListPlayersIdsRequest.Filter
 */
export const ListPlayersIdsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ListPlayersIdsRequest.Filter",
  () => [
    { no: 1, name: "period", kind: "message", T: TimeRange },
    { no: 2, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 3, name: "actions", kind: "enum", T: proto3.getEnumType(Action), repeated: true },
    { no: 4, name: "methods", kind: "enum", T: proto3.getEnumType(Method), repeated: true },
    { no: 5, name: "moneys", kind: "enum", T: proto3.getEnumType(Money), repeated: true },
    { no: 6, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "gameTypes", kind: "enum", T: proto3.getEnumType(Game), repeated: true },
    { no: 8, name: "sum", kind: "message", T: Int64Range },
    { no: 9, name: "regions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "excludeTestPlayers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "registered", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 12, name: "firstDeposit", kind: "message", T: ListPlayersIdsRequest_Filter_FirstDeposit },
  ],
  {localName: "ListPlayersIdsRequest_Filter"},
);

/**
 * @generated from message stat.ListPlayersIdsRequest.Filter.FirstDeposit
 */
export const ListPlayersIdsRequest_Filter_FirstDeposit = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ListPlayersIdsRequest.Filter.FirstDeposit",
  () => [
    { no: 1, name: "firstDepositProject", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 2, name: "firstDepositAmount", kind: "message", T: Int64Range },
  ],
  {localName: "ListPlayersIdsRequest_Filter_FirstDeposit"},
);

/**
 * Deprecated
 *
 * @generated from message stat.ListPlayersIdsResponse
 */
export const ListPlayersIdsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ListPlayersIdsResponse",
  () => [
    { no: 1, name: "playerIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * Event summary
 *
 * @generated from message stat.Summary
 */
export const Summary = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Summary",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "first", kind: "message", T: Summary_Info },
    { no: 4, name: "last", kind: "message", T: Summary_Info },
  ],
);

/**
 * @generated from message stat.Summary.Info
 */
export const Summary_Info = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Summary.Info",
  () => [
    { no: 1, name: "sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "time", kind: "message", T: Timestamp },
  ],
  {localName: "Summary_Info"},
);

/**
 * Lite Events summary
 * all sum in coins
 *
 * @generated from message stat.EventsLiteSummary
 */
export const EventsLiteSummary = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsLiteSummary",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depositCasino", kind: "message", T: Summary },
    { no: 3, name: "depositSport", kind: "message", T: Summary },
    { no: 4, name: "moneyoutCasino", kind: "message", T: Summary },
    { no: 5, name: "moneyoutSport", kind: "message", T: Summary },
    { no: 6, name: "betCasino", kind: "message", T: EventsLiteSummary_Bet },
    { no: 7, name: "betSport", kind: "message", T: EventsLiteSummary_Bet },
    { no: 8, name: "betCalcSport", kind: "message", T: EventsLiteSummary_BetCalc },
    { no: 9, name: "winCasino", kind: "message", T: EventsLiteSummary_Win },
    { no: 10, name: "winSport", kind: "message", T: EventsLiteSummary_Win },
  ],
);

/**
 * Bet
 *
 * @generated from message stat.EventsLiteSummary.Bet
 */
export const EventsLiteSummary_Bet = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsLiteSummary.Bet",
  () => [
    { no: 2, name: "real", kind: "message", T: Summary },
    { no: 3, name: "realRollback", kind: "message", T: Summary },
  ],
  {localName: "EventsLiteSummary_Bet"},
);

/**
 * Bet Calc
 *
 * @generated from message stat.EventsLiteSummary.BetCalc
 */
export const EventsLiteSummary_BetCalc = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsLiteSummary.BetCalc",
  () => [
    { no: 1, name: "realCalc", kind: "message", T: Summary },
    { no: 2, name: "realCalcRollback", kind: "message", T: Summary },
  ],
  {localName: "EventsLiteSummary_BetCalc"},
);

/**
 * Win
 *
 * @generated from message stat.EventsLiteSummary.Win
 */
export const EventsLiteSummary_Win = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsLiteSummary.Win",
  () => [
    { no: 2, name: "real", kind: "message", T: Summary },
    { no: 3, name: "realRollback", kind: "message", T: Summary },
  ],
  {localName: "EventsLiteSummary_Win"},
);

/**
 * GetPlayerStat events summary request
 *
 * @generated from message stat.GetLiteStatRequest
 */
export const GetLiteStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetLiteStatRequest",
  () => [
    { no: 1, name: "period", kind: "message", T: TimeRange },
    { no: 2, name: "playerIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * GetPlayerStat events summary response
 *
 * @generated from message stat.GetLiteStatResponse
 */
export const GetLiteStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetLiteStatResponse",
  () => [
    { no: 2, name: "items", kind: "message", T: EventsLiteSummary, repeated: true },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * GetPlayerStatRequest Events summary request
 *
 * @generated from message stat.GetPlayerStatRequest
 */
export const GetPlayerStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerStatRequest",
  () => [
    { no: 1, name: "period", kind: "message", T: TimeRange },
    { no: 2, name: "playerIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * GetPlayerStatResponse events summary response
 *
 * @generated from message stat.GetPlayerStatResponse
 */
export const GetPlayerStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerStatResponse",
  () => [
    { no: 2, name: "items", kind: "message", T: EventsLiteSummary, repeated: true },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * Events summary
 *
 * @generated from message stat.EventsSummary
 */
export const EventsSummary = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary",
  () => [
    { no: 24, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "depositCasino", kind: "message", T: EventsSummary_Deposit },
    { no: 2, name: "depositSport", kind: "message", T: EventsSummary_Deposit },
    { no: 3, name: "bonusCasino", kind: "message", T: EventsSummary_Bonus },
    { no: 4, name: "bonusSport", kind: "message", T: EventsSummary_Bonus },
    { no: 18, name: "transferCasinoToSport", kind: "message", T: Summary },
    { no: 19, name: "transferSportToCasino", kind: "message", T: Summary },
    { no: 5, name: "moneyoutCasino", kind: "message", T: Summary },
    { no: 6, name: "moneyoutSport", kind: "message", T: Summary },
    { no: 7, name: "pincoinAdd", kind: "message", T: Summary },
    { no: 8, name: "pincoinExchangeCasino", kind: "message", T: Summary },
    { no: 9, name: "pincoinExchangeSport", kind: "message", T: Summary },
    { no: 10, name: "betCasino", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: EventsSummary_Bet} },
    { no: 22, name: "betCasinos", kind: "message", T: EventsSummary_Bet },
    { no: 11, name: "betSport", kind: "message", T: EventsSummary_Bet },
    { no: 12, name: "winCasino", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: EventsSummary_Win} },
    { no: 23, name: "winCasinos", kind: "message", T: EventsSummary_Win },
    { no: 13, name: "winSport", kind: "message", T: EventsSummary_Win },
    { no: 14, name: "lotteryCasino", kind: "message", T: EventsSummary_Lottery },
    { no: 15, name: "lotterySport", kind: "message", T: EventsSummary_Lottery },
    { no: 16, name: "freespin", kind: "message", T: EventsSummary_Freespin },
    { no: 17, name: "freebet", kind: "message", T: EventsSummary_Freebet },
    { no: 20, name: "tournamentCasino", kind: "message", T: EventsSummary_Tournament },
    { no: 21, name: "tournamentSport", kind: "message", T: EventsSummary_Tournament },
  ],
);

/**
 * Deposit
 *
 * @generated from message stat.EventsSummary.Deposit
 */
export const EventsSummary_Deposit = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Deposit",
  () => [
    { no: 1, name: "try", kind: "message", T: Summary },
    { no: 2, name: "tryNd", kind: "message", T: Summary },
    { no: 3, name: "real", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Deposit"},
);

/**
 * Bonus
 *
 * @generated from message stat.EventsSummary.Bonus
 */
export const EventsSummary_Bonus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Bonus",
  () => [
    { no: 1, name: "add", kind: "message", T: Summary },
    { no: 2, name: "wager", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Bonus"},
);

/**
 * Bet
 *
 * @generated from message stat.EventsSummary.Bet
 */
export const EventsSummary_Bet = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Bet",
  () => [
    { no: 1, name: "game", kind: "enum", T: proto3.getEnumType(Game) },
    { no: 2, name: "real", kind: "message", T: Summary },
    { no: 3, name: "realRollback", kind: "message", T: Summary },
    { no: 4, name: "bonus", kind: "message", T: Summary },
    { no: 5, name: "bonusRollback", kind: "message", T: Summary },
    { no: 6, name: "realCalc", kind: "message", T: Summary },
    { no: 7, name: "realCalcRollback", kind: "message", T: Summary },
    { no: 8, name: "bonusCalc", kind: "message", T: Summary },
    { no: 9, name: "bonusCalcRollback", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Bet"},
);

/**
 * Win
 *
 * @generated from message stat.EventsSummary.Win
 */
export const EventsSummary_Win = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Win",
  () => [
    { no: 1, name: "game", kind: "enum", T: proto3.getEnumType(Game) },
    { no: 2, name: "real", kind: "message", T: Summary },
    { no: 3, name: "realRollback", kind: "message", T: Summary },
    { no: 4, name: "bonus", kind: "message", T: Summary },
    { no: 5, name: "bonusRollback", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Win"},
);

/**
 * Lottery
 *
 * @generated from message stat.EventsSummary.Lottery
 */
export const EventsSummary_Lottery = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Lottery",
  () => [
    { no: 1, name: "add", kind: "message", T: Summary },
    { no: 2, name: "realWin", kind: "message", T: Summary },
    { no: 3, name: "bonusWin", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Lottery"},
);

/**
 * Freespin - only casino
 *
 * @generated from message stat.EventsSummary.Freespin
 */
export const EventsSummary_Freespin = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Freespin",
  () => [
    { no: 1, name: "add", kind: "message", T: Summary },
    { no: 2, name: "win", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Freespin"},
);

/**
 * Freebet - only sport
 *
 * @generated from message stat.EventsSummary.Freebet
 */
export const EventsSummary_Freebet = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Freebet",
  () => [
    { no: 1, name: "add", kind: "message", T: Summary },
    { no: 2, name: "bet", kind: "message", T: Summary },
    { no: 7, name: "betRollback", kind: "message", T: Summary },
    { no: 3, name: "win", kind: "message", T: Summary },
    { no: 4, name: "winRollback", kind: "message", T: Summary },
    { no: 5, name: "winWithBetSum", kind: "message", T: Summary },
    { no: 6, name: "winWithBetSumRollback", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Freebet"},
);

/**
 * Tournament
 *
 * @generated from message stat.EventsSummary.Tournament
 */
export const EventsSummary_Tournament = /*@__PURE__*/ proto3.makeMessageType(
  "stat.EventsSummary.Tournament",
  () => [
    { no: 2, name: "realWin", kind: "message", T: Summary },
  ],
  {localName: "EventsSummary_Tournament"},
);

/**
 * @generated from message stat.GetSummaryRequest
 */
export const GetSummaryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetSummaryRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "period", kind: "message", T: TimeRange },
    { no: 3, name: "filter", kind: "message", T: GetSummaryRequest_Filter },
  ],
);

/**
 * @generated from message stat.GetSummaryRequest.Filter
 */
export const GetSummaryRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetSummaryRequest.Filter",
  () => [
    { no: 3, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 4, name: "action", kind: "enum", T: proto3.getEnumType(Action), repeated: true },
  ],
  {localName: "GetSummaryRequest_Filter"},
);

/**
 * @generated from message stat.GetSummaryResponse
 */
export const GetSummaryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetSummaryResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "item", kind: "message", T: EventsSummary },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetMoneyOutRequest
 */
export const GetMoneyOutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetMoneyOutRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "period", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message stat.GetMoneyOutResponse
 */
export const GetMoneyOutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetMoneyOutResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "casino", kind: "message", T: Summary },
    { no: 3, name: "sport", kind: "message", T: Summary },
    { no: 4, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetDepositsInfoRequest
 */
export const GetDepositsInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetDepositsInfoRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "period", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message stat.GetDepositsInfoResponse
 */
export const GetDepositsInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetDepositsInfoResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "casino", kind: "message", T: Summary },
    { no: 3, name: "sport", kind: "message", T: Summary },
    { no: 4, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetTaxEventsRequest
 */
export const GetTaxEventsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetTaxEventsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sorting", kind: "message", T: Sorting$1, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest$1 },
  ],
);

/**
 * @generated from message stat.GetTaxEventsResponse
 */
export const GetTaxEventsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetTaxEventsResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "events", kind: "message", T: TaxEvent, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationResponse$1 },
  ],
);

/**
 * @generated from message stat.TaxEvent
 */
export const TaxEvent = /*@__PURE__*/ proto3.makeMessageType(
  "stat.TaxEvent",
  () => [
    { no: 1, name: "event", kind: "message", T: PlayerEvent },
    { no: 2, name: "taxSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

